<template lang="html" src="./pageDiscounts.template.vue"></template>

<style lang="scss" src="./pageDiscounts.scss"></style>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';
import isEmail from 'validator/lib/isEmail';

const i18nData = require('./pageDiscounts.i18n');

export default {
  name: 'PageDiscounts',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headersDiscounts: [],
      discountCodes: [],
      searchCode: '',
      modalAddDiscountCode: false,
      formCode: '',
      formTrialPeriod: null,
      formDiscounts: [],
      headersFormDiscounts: [],
      formEmail: null,
      formMaxUtilization: null,
      formMaxUtilizationInfinite: true,
      formOfferBasic: true,
      formOfferPro: true,
      formOfferEnterprise: false,
      rules: {},
      idDiscount: 0,
      warehouseOffers: null,
      discountApplicableOffers: [],
    };
  },
  created() {
    this.getDiscountCodes();
    this.getWarehouseOffers();
  },
  mounted() {
    this.headersDiscounts = [
      { text: this.$t('code'), value: 'code' },
      {
        text: this.$t('RemainingUtilization'),
        value: 'remainingUtilization',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('TotalUtilization'),
        value: 'totalUtilization',
        align: 'center',
      },
      {
        text: this.$t('Email'),
        value: 'email',
        align: 'center',
        sortable: false,
      },
      { text: this.$t('Offers'), value: 'offers', align: 'center' },
      {
        text: this.$t('trialPeriod'),
        value: 'trialPeriod',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('discounts'),
        value: 'discounts',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
    this.headersFormDiscounts = [
      {
        text: this.$t('discountPercentage'),
        value: 'discountPercentage',
        sortable: true,
      },
      {
        text: this.$t('durationMonths'),
        value: 'durationMonths',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('Lifetime'),
        value: 'lifetime',
        sortable: false,
      },
      {
        text: this.$t('Action'),
        value: 'action',
        sortable: false,
        align: 'center',
      },
    ];
    this.rules = {
      rulesCode: [(value) => !!value || value === 0 || this.$t('Required')],
      rulesTrialPeriod: [
        (value) => !!value || value === 0 || this.$t('Required'),
        (value) => value >= 0 || this.$t('MustBePositive'),
      ],
      rulesDiscountPercentage: [
        (value) => !!value || value === 0 || this.$t('Required'),
        (value) => value >= 1 || this.$t('MustBeStrictlyPositive'),
        (value) => value <= 100 || this.$t('MustBeBelow100'),
      ],
      rulesDiscountMonths: [
        (value) => !!value || value === 0 || this.$t('Required'),
        (value) => value >= 1 || this.$t('MustBeStrictlyPositive'),
      ],
      rulesEmail: [
        (value) => (value ? isEmail(value) || this.$t('UnvalidEmail') : true),
      ],
      rulesMaxUtilization: [
        (value) =>
          !!value || !!this.formMaxUtilizationInfinite || this.$t('Required'),
        (value) => value >= 0 || this.$t('MustBePositive'),
      ],
    };
  },
  methods: {
    getDiscountCodes() {
      this.discountCodes = [];
      this.$apiInstance.getAllDiscountCodes().then((codes) => {
        for (const code of codes) {
          const sortedDiscounts = [];
          if (code.discounts) {
            const keys = Object.keys(code.discounts);
            keys.sort(function(a, b) {
              return parseInt(b) - parseInt(a);
            });
            for (const key of keys) {
              sortedDiscounts.push([key, code.discounts[key]]);
            }
          }
          let offers = '';
          for (let i = 0; i < code.applicableOffers.length; i++) {
            offers += code.applicableOffers[i];
            if (i !== code.applicableOffers.length - 1) {
              offers += ', ';
            }
          }
          this.discountCodes.push({
            code: code.code,
            trialPeriod: code.trial_period,
            discounts: sortedDiscounts,
            email: code.managerEmail ? code.managerEmail : ' - ',
            totalUtilization: code.totalUtilization,
            offers: offers,
            remainingUtilization:
              code.maxUtilization === -1
                ? this.$t('Infinite')
                : Math.max(0, code.maxUtilization - code.totalUtilization),
            editRemainingUtilization: false,
            formMaxUtilizationInfinite: code.maxUtilization === -1,
            formRemainingUtilization:
              code.maxUtilization === -1
                ? null
                : Math.max(0, code.maxUtilization - code.totalUtilization),
          });
        }
      });
    },
    getWarehouseOffers() {
      this.$apiInstance.getAdminWarehouseOffers()
        .then((data) => {
          this.warehouseOffers = data;
        }, (error) => {
          this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
        });
    },
    deleteDiscountCode(code) {
      this.$apiInstance.deleteDiscountCode(code).then(() => {
        this.getDiscountCodes();
      });
    },
    resetFormAddDiscountCode() {
      this.modalAddDiscountCode = false;
      this.formDiscounts = [];
      this.formCode = '';
      this.formTrialPeriod = null;
      this.formEmail = null;
      this.formMaxUtilization = null;
      this.formMaxUtilizationInfinite = true;
      this.formOfferBasic = true;
      this.formOfferPro = true;
      this.formOfferEnterprise = false;
    },
    addDiscountLine() {
      this.formDiscounts.push({
        idDiscount: this.idDiscount,
        discountPercentage: 0,
        durationMonths: 0,
        lifetime: false,
      });
      this.idDiscount++;
    },
    deleteItemForm(item) {
      for (let index = 0; index < this.formDiscounts.length; index++) {
        if (this.formDiscounts[index].idDiscount === item.idDiscount) {
          this.formDiscounts.splice(index, 1);
          break;
        }
      }
    },
    addDiscountCode() {
      const self = this;
      if (this.$refs.discountsForm.validate()) {
        const discountsObj = {};
        for (const reduction of this.formDiscounts) {
          discountsObj[reduction.discountPercentage] = reduction.lifetime
            ? '-1'
            : reduction.durationMonths;
        }
        const applicableOffers = [];
        this.discountApplicableOffers.forEach(offer => {
          applicableOffers.push(offer);
        });
        // if (this.formOfferBasic) {
        //   applicableOffers.push('BASIC');
        // }
        // if (this.formOfferPro) {
        //   applicableOffers.push('PRO');
        // }
        // if (this.formOfferEnterprise) {
        //   applicableOffers.push('ENTERPRISE');
        // }
        const addDiscountCodeBody = new this.$BcmModel.AddDiscountCodeBody(
          this.formCode,
          parseInt(this.formTrialPeriod),
          discountsObj,
          this.formMaxUtilizationInfinite
            ? -1
            : parseInt(this.formMaxUtilization),
          applicableOffers,
          this.formEmail && this.formEmail !== '' ? this.formEmail : ''
        );
        this.$apiInstance
          .addDiscountCode(addDiscountCodeBody)
          .then((code) => {
            this.$notification.notify('SUCCESS', this.$t('theCode') + code.code + this.$t('hasBeenCreated'));
            self.resetFormAddDiscountCode();
            self.getDiscountCodes();
          })
          .catch((error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          });
      }
    },
    validateModification(item) {
      if (
        (!item.formMaxUtilizationInfinite && !item.formRemainingUtilization) ||
        item.formRemainingUtilization < 0
      ) {
        this.$notification.notify('DANGER', this.$t('InvalidModification'));
      } else {
        const newMaxUtilization = item.formMaxUtilizationInfinite
          ? -1
          : item.totalUtilization + parseInt(item.formRemainingUtilization);
        const modifyDiscountCodeMaxUtilizationBody = new this.$BcmModel.ModifyDiscountCodeMaxUtilizationBody(
          newMaxUtilization
        );
        this.$apiInstance
          .modifyDiscountCodeMaxUtilization(
            item.code,
            modifyDiscountCodeMaxUtilizationBody
          )
          .then(() => {
            this.$notification.notify('SUCCESS', this.$t('theCode') + item.code + this.$t('hasBeenModified'));
            this.getDiscountCodes();
          })
          .catch((error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          });
      }
    },
    cancelModification(item) {
      item.editRemainingUtilization = false;
      item.formMaxUtilizationInfinite =
        item.remainingUtilization === this.$t('Infinite'); // A bit ugly test but should work
      item.formRemainingUtilization =
        item.remainingUtilization === this.$t('Infinite')
          ? null
          : item.remainingUtilization;
    },
  },
};
</script>
