var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-discounts"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('discountCodes')))])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col'), _c('v-col', [_c('v-spacer', [_c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchCode,
      callback: function ($$v) {
        _vm.searchCode = $$v;
      },
      expression: "searchCode"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headersDiscounts,
      "items": _vm.discountCodes,
      "search": _vm.searchCode,
      "sort-by": "code",
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.remainingUtilization`,
      fn: function (_ref) {
        var item = _ref.item;
        return [!item.editRemainingUtilization ? _c('div', [_vm._v(" " + _vm._s(item.remainingUtilization) + " "), _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "x-small": "",
            "color": "warning",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              item.editRemainingUtilization = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v(" fas fa-pen ")])], 1)], 1) : _c('div', [_c('v-form', {
          ref: "editMaxUtilizationForm"
        }, [_c('v-row', [_c('v-col', {
          staticClass: "pb-0",
          attrs: {
            "cols": "8"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "number",
            "label": _vm.$t('MaxUtilization'),
            "disabled": item.formMaxUtilizationInfinite
          },
          model: {
            value: item.formRemainingUtilization,
            callback: function ($$v) {
              _vm.$set(item, "formRemainingUtilization", $$v);
            },
            expression: "item.formRemainingUtilization"
          }
        })], 1), _c('v-col', {
          staticClass: "px-0 pb-0",
          attrs: {
            "cols": "2"
          }
        }, [_c('v-checkbox', {
          model: {
            value: item.formMaxUtilizationInfinite,
            callback: function ($$v) {
              _vm.$set(item, "formMaxUtilizationInfinite", $$v);
            },
            expression: "item.formMaxUtilizationInfinite"
          }
        })], 1), _c('v-col', {
          staticClass: "px-0 pb-0 d-flex",
          attrs: {
            "cols": "2",
            "align-center": "",
            "justify-center": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-infinity ")])], 1)], 1), _c('v-row', [_c('v-col', {
          staticClass: "pt-0",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "x-small": "",
            "color": "success",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              return _vm.validateModification(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v(" fas fa-check ")])], 1), _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "x-small": "",
            "color": "error",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              return _vm.cancelModification(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v(" fas fa-times ")])], 1)], 1)], 1)], 1)], 1)];
      }
    }, {
      key: `item.discounts`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.discounts.length === 0 ? _c('div', {
          staticClass: "font-italic"
        }, [_vm._v(" " + _vm._s(_vm.$t('noMonthlyDiscount')) + " ")]) : _vm._l(item.discounts, function (discount, index) {
          return _c('span', {
            key: index
          }, [_c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v(_vm._s(discount[0]) + "%")]), _c('span', [_vm._v("(")]), discount[1] !== -1 ? _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v(" " + _vm._s(discount[1]) + " " + _vm._s(_vm.$t('months')) + " ")]) : _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v(_vm._s(_vm.$t('lifetime')))]), _c('span', [_vm._v(")")]), parseInt(index) !== item.discounts.length - 1 ? _c('span', [_vm._v(">")]) : _vm._e()]);
        })];
      }
    }, {
      key: `item.action`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "color": "error"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteDiscountCode(item.code);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-trash ")])], 1)];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "table-footer-prepend d-flex pl-2 align-center"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.modalAddDiscountCode = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-plus ")]), _vm._v(" " + _vm._s(_vm.$t('addCode')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "width": "650"
    },
    model: {
      value: _vm.modalAddDiscountCode,
      callback: function ($$v) {
        _vm.modalAddDiscountCode = $$v;
      },
      expression: "modalAddDiscountCode"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('addCode')))]), _c('v-divider'), _c('v-card-text', [_c('v-form', {
    ref: "discountsForm"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('code'),
      "rules": _vm.rules.rulesCode
    },
    model: {
      value: _vm.formCode,
      callback: function ($$v) {
        _vm.formCode = $$v;
      },
      expression: "formCode"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": _vm.$t('trialPeriod'),
      "rules": _vm.rules.rulesTrialPeriod
    },
    model: {
      value: _vm.formTrialPeriod,
      callback: function ($$v) {
        _vm.formTrialPeriod = $$v;
      },
      expression: "formTrialPeriod"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "email",
      "label": _vm.$t('EmailForm'),
      "rules": _vm.rules.rulesEmail
    },
    model: {
      value: _vm.formEmail,
      callback: function ($$v) {
        _vm.formEmail = $$v;
      },
      expression: "formEmail"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": _vm.$t('MaxUtilization'),
      "disabled": _vm.formMaxUtilizationInfinite,
      "rules": _vm.rules.rulesMaxUtilization
    },
    model: {
      value: _vm.formMaxUtilization,
      callback: function ($$v) {
        _vm.formMaxUtilization = $$v;
      },
      expression: "formMaxUtilization"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "1",
      "align": "center"
    }
  }, [_c('span', [_vm._v("Inf.")]), _c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "align": "center"
    },
    model: {
      value: _vm.formMaxUtilizationInfinite,
      callback: function ($$v) {
        _vm.formMaxUtilizationInfinite = $$v;
      },
      expression: "formMaxUtilizationInfinite"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "py-0"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Plans")])])], 1), _c('v-row', [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.warehouseOffers,
      "item-text": "_id",
      "item-value": "_id",
      "outlined": "",
      "chips": "",
      "small-chips": "",
      "multiple": "",
      "label": _vm.$t('planSelectedForDiscount'),
      "rules": [function (v) {
        return !!(v && v.length) || _this.$t('AtLeastOne');
      }]
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function () {
        return [_c('v-list-item', {
          attrs: {
            "ripple": ""
          },
          on: {
            "click": function ($event) {
              _vm.discountApplicableOffers.length === _vm.warehouseOffers.length ? _vm.discountApplicableOffers = [] : _vm.discountApplicableOffers = _vm.warehouseOffers.map(function (offer) {
                return offer._id;
              });
            }
          }
        }, [_c('v-list-item-title', {
          class: _vm.discountApplicableOffers.length === _vm.warehouseOffers.length ? 'success--text' : ''
        }, [_vm._v(" " + _vm._s(_vm.$t("allPlans")) + " ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.discountApplicableOffers,
      callback: function ($$v) {
        _vm.discountApplicableOffers = $$v;
      },
      expression: "discountApplicableOffers"
    }
  })], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-0",
    attrs: {
      "headers": _vm.headersFormDiscounts,
      "hide-default-footer": true,
      "items": _vm.formDiscounts
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          staticClass: "font-weight-bold pr-2",
          attrs: {
            "flat": "",
            "color": "white"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('discounts')) + " "), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "fab": "",
            "x-small": "",
            "color": "info",
            "disabled": _vm.formDiscounts.length >= 10
          },
          on: {
            "click": _vm.addDiscountLine
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-plus ")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('tr', {
          staticClass: "align-center"
        }, [_c('td', [_c('v-text-field', {
          attrs: {
            "type": "number",
            "rules": _vm.rules.rulesDiscountPercentage,
            "dense": ""
          },
          model: {
            value: item.discountPercentage,
            callback: function ($$v) {
              _vm.$set(item, "discountPercentage", $$v);
            },
            expression: "item.discountPercentage"
          }
        })], 1), !item.lifetime ? _c('td', [_c('v-text-field', {
          attrs: {
            "type": "number",
            "rules": _vm.rules.rulesDiscountMonths,
            "dense": ""
          },
          model: {
            value: item.durationMonths,
            callback: function ($$v) {
              _vm.$set(item, "durationMonths", $$v);
            },
            expression: "item.durationMonths"
          }
        })], 1) : _c('td', [_c('v-text-field', {
          staticClass: "text-center",
          attrs: {
            "disabled": "",
            "dense": "",
            "value": "Inf"
          }
        })], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('v-checkbox', {
          model: {
            value: item.lifetime,
            callback: function ($$v) {
              _vm.$set(item, "lifetime", $$v);
            },
            expression: "item.lifetime"
          }
        })], 1), _c('td', [_c('v-btn', {
          attrs: {
            "small": "",
            "rounded": "",
            "color": "error"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteItemForm(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-trash ")])], 1)], 1)])];
      }
    }])
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.resetFormAddDiscountCode
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.addDiscountCode
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('create')) + " ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }